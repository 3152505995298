var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('a-form-model',{staticStyle:{"margin-bottom":"2vh"},attrs:{"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"资产编号"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"资产名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"200px"},attrs:{"allowClear":true,"placeholder":"资产状态","mode":"multiple"},model:{value:(_vm.form.statusList),callback:function ($$v) {_vm.$set(_vm.form, "statusList", $$v)},expression:"form.statusList"}},_vm._l((_vm.statusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1),_c('a-col',{attrs:{"span":2}})],1)],1),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"rowKey":"id","pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"资产编号","data-index":"code","align":"center"}}),_c('a-table-column',{attrs:{"title":"资产名称","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"品牌","data-index":"brand"}}),_c('a-table-column',{attrs:{"title":"规格型号","data-index":"specification"}}),_c('a-table-column',{attrs:{"title":"单位","data-index":"unit","align":"center"}}),_c('a-table-column',{attrs:{"title":"单价","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('Money',{attrs:{"money":text.price}})]}}])}),_c('a-table-column',{attrs:{"title":"资产状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"AssetMaterialStatus","dictValue":text.status,"iconType":"text"}})]}}])}),_c('a-table-column',{attrs:{"title":"入库时间","data-index":"createAt","align":"center"}}),_c('a-table-column',{attrs:{"title":"操作","width":"120px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionEdit),expression:"permissionEdit"}]},[_c('a',{on:{"click":function($event){return _vm.edit(text)}}},[_vm._v("编辑")]),_c('a',{staticClass:"danger",on:{"click":function($event){$event.preventDefault();return _vm.deleteText(text)}}},[_vm._v("删除")])])]}}])})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/page-header-bg.png"),"alt":""}}),_c('span',{staticClass:"text"},[_vm._v("计量资产台账")])])
}]

export { render, staticRenderFns }