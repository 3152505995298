<template>
  <div>
    <div class="page-header">
      <img class="bg" src="@/assets/page-header-bg.png" alt="" />
      <span class="text">计量资产台账</span>
    </div>

    <div>
      <a-form-model
        layout="inline"
        @keyup.enter.native="query"
        style="margin-bottom: 2vh"
      >
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input
                placeholder="资产编号"
                style="width: 150px"
                v-model="form.code"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-input
                placeholder="资产名称"
                style="width: 150px"
                v-model="form.name"
              />
            </a-form-model-item>

            <!-- <a-form-model-item>
              <a-select placeholder="保管人" v-model="form.chargerId" :allowClear="true" style="width: 150px">
                <a-select-option v-for="item in userList" :key="item.id" :value="item.id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item> -->

            <a-form-model-item>
              <a-select
                :allowClear="true"
                v-model="form.statusList"
                placeholder="资产状态"
                style="width: 200px"
                mode="multiple"
              >
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button type="primary" @click="query">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <!-- <div class="right">
              <a-button type="primary" @click="$router.push(`${$route.path}/add`)">创建资产</a-button>
            </div> -->
          </a-col>
        </a-row>
      </a-form-model>

      <a-table
        bordered
        @change="onChange"
        :data-source="list"
        :loading="loading"
        rowKey="id"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="资产编号" data-index="code" align="center" />
        <a-table-column title="资产名称" data-index="name" />
        <a-table-column title="品牌" data-index="brand" />
        <a-table-column title="规格型号" data-index="specification" />

        <a-table-column title="单位" data-index="unit" align="center" />
        <a-table-column title="单价" align="right">
          <template slot-scope="text">
            <Money :money="text.price" />
          </template>
        </a-table-column>

        <a-table-column title="资产状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="AssetMaterialStatus"
              :dictValue="text.status"
              iconType="text"
            />
          </template>
        </a-table-column>

        <a-table-column title="入库时间" data-index="createAt" align="center">
        </a-table-column>

        <!-- <a-table-column title="保管人" data-index="charger" align="center">
        </a-table-column> -->

        <a-table-column title="操作" width="120px" align="center">
          <template slot-scope="text">
            <a-space v-show="permissionEdit">
              <a @click="edit(text)">编辑</a>
              <a class="danger" @click.prevent="deleteText(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </div>
</template>
<script>
import { fetchList } from "../api/free";
import { remove } from "../api/list";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      form: {},
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
    };
  },
  mounted() {
    this.getUserList();
    this.getList();
  },
  computed: {
    ...mapState("userSelector", ["userList"]),

    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("AssetMaterialStatus");
    },

    //增加权限显示控制
    ...mapGetters("auth", ["hasPermission"]),
    permissionAdd() {
      return this.hasPermission("model-analysis:asset:material:add");
    },
    permissionEdit() {
      return this.hasPermission("model-analysis:asset:material:update");
    },
  },
  methods: {
    ...mapActions("userSelector", ["getUserList"]),

    getList() {
      this.loading = true;
      let { current, pageSize, form } = this;

      fetchList({
        pageNum: current,
        pageSize,
        ...form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    query() {
      this.current = 1;
      this.getList();
    },

    edit(text) {
      window.localStorage.setItem("materialDetail", JSON.stringify(text));
      this.$router.push(`${this.$route.path}/edit`);
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>




<style lang="less" scoped>
.page-header {
  position: relative;
  margin-bottom: 1vh;

  .bg {
    width: 240px;
    display: block;
  }

  .text {
    position: absolute;
    top: 10%;
    left: 0;
    bottom: 0;
  }
}
</style>